import React, { useState, useEffect } from "react";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import "../styles/ContinentScreen.css";
//
const continents = {
  "NORTE AMÉRICA": ["Estados Unidos", "Canadá", "México"],
  "CENTRO AMÉRICA": [
    "Belice",
    "Costa Rica",
    "El Salvador",
    "Guatemala",
    "Honduras",
    "Nicaragua",
    "Panamá",
    "República Dominicana",
    "Aruba",
    "Puerto Rico",
  ],
  "SUR AMÉRICA": [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Ecuador",
    "Guyana",
    "Paraguay",
    "Perú",
    "Surinam",
    "Uruguay",
    "Venezuela",
  ],
  EUROPA: [
    "España",
    "Francia",
    "Alemania",
    "Italia",
    "Reino Unido",
    "Portugal",
    "Bélgica",
    "Países Bajos",
    "Suecia",
    "Noruega",
    "Finlandia",
    "Dinamarca",
    "Polonia",
    "Rusia",
    "Ucrania",
    "Suiza",
    "Austria",
    "Grecia",
    "Irlanda",
    "Hungría",
    "República Checa",
    "Eslovaquia",
    "Bulgaria",
    "Croacia",
    "Rumanía",
    "Serbia",
    "Eslovenia",
    "Estonia",
    "Letonia",
    "Lituania",
    "Moldavia",
    "Albania",
    "Kosovo",
    "Montenegro",
    "Macedonia del Norte",
    "Andorra",
    "San Marino",
    "Liechtenstein",
    "Malta",
  ],
  ÁFRICA: [
    "Nigeria",
    "Egipto",
    "Sudáfrica",
    "Etiopía",
    "Kenia",
    "Tanzania",
    "Uganda",
    "Sudán",
    "Argelia",
    "Angola",
    "Ghana",
    "Mozambique",
    "Madagascar",
    "Camerún",
    "Costa de Marfil",
    "Malí",
    "Níger",
    "Burkina Faso",
    "Malawi",
    "Zambia",
    "Senegal",
    "Chad",
    "RDC (República Democrática del Congo)",
    "Rwanda",
    "Benín",
    "Burundi",
    "Togo",
    "Congo",
    "Sierra Leona",
    "Liberia",
    "Mauritania",
    "Botsuana",
    "Gambia",
    "Guinea",
    "Guinea-Bisáu",
    "Lesoto",
    "Comoras",
    "Cabo Verde",
    "Santo Tomé y Príncipe",
    "Yibuti",
    "Esuatini",
  ],
  ASIA: [
    "China",
    "India",
    "Japón",
    "Corea del Sur",
    "Corea del Norte",
    "Indonesia",
    "Pakistán",
    "Bangladés",
    "Filipinas",
    "Vietnam",
    "Irán",
    "Tailandia",
    "Myanmar",
    "Malasia",
    "Arabia Saudita",
    "Uzbekistán",
    "Afganistán",
    "Yemen",
    "Nepal",
    "Sri Lanka",
    "Kazajistán",
    "Siria",
    "Camboya",
    "Jordanía",
    "Azerbaiyán",
    "Tayikistán",
    "Israel",
    "Líbano",
    "Laos",
    "Kuwait",
    "Oman",
    "Kirgistán",
    "Turkmenistán",
    "Qatar",
    "Bahréin",
    "Maldivas",
    "Brunéi",
    "Singapur",
    "Timor Oriental",
    "Bhután",
  ],
};

const ContinentScreen = () => {
  const [selectedContinent, setSelectedContinent] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [brokers, setBrokers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleContinentClick = (continent) => {
    setSelectedContinent(continent);
    setSelectedCountry(null);
    setSelectedBroker(null);
  };

  const handleCountryClick = (country) => {
    setSelectedCountry(country);
    setSelectedBroker(null);
    setIsLoading(true);
    fetch("https://bombazoo.com/api/broker/query", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pais: country,
        fromVisa: true,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setBrokers(data.brokers);
        } else {
          setBrokers([]);
        }
      })
      .catch((err) => {
        setBrokers([]);
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBrokerClick = (broker) => {
    setSelectedBroker(broker);
  };

  const closeModal = () => {
    setSelectedBroker(null);
  };

  const goBack = () => {
    if (selectedBroker) {
      setSelectedBroker(null);
    } else if (selectedCountry) {
      setSelectedCountry(null);
    } else if (selectedContinent) {
      setSelectedContinent(null);
    }
  };

  useEffect(() => {
    fetch("https://bombazoo.com/api/broker/track-visit", {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Visitas incrementadas: ", data.uniqueVisitsToday);
      })
      .catch((err) => {
        console.error("Error al registrar la visita", err);
      });
  }, []);

  return (
    <div className="continent-screen">
      <div className="continent-list">
        {(selectedContinent || selectedCountry) && (
          <button className="back-button" onClick={goBack}>
            ← Volver
          </button>
        )}

        {(selectedContinent || selectedCountry) && (
          <p className="current-selection">
            {selectedCountry || selectedContinent}
          </p>
        )}

        {!selectedContinent ? (
          Object.keys(continents).map((continent) => (
            <button
              key={continent}
              className="continent-button"
              onClick={() => handleContinentClick(continent)}
            >
              {continent}
            </button>
          ))
        ) : !selectedCountry ? (
          continents[selectedContinent].map((country) => (
            <button
              key={country}
              className="continent-button"
              onClick={() => handleCountryClick(country)}
            >
              {country}
            </button>
          ))
        ) : isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
            <p>Cargando brokers...</p>
          </div>
        ) : brokers.length > 0 ? (
          brokers.map((broker) => (
            <button
              key={broker.id}
              className="continent-button broker-item"
              onClick={() => handleBrokerClick(broker)}
            >
              <div className="broker-info">
                {broker.activo_visa === 1 ? (
                  <img
                    className="broker-status-icon"
                    src={broker.image_url ? broker.image_url : "/Logo-03.png"}
                    alt="Broker"
                  />
                ) : null}
                <span
                  className={`broker-name ${
                    broker.activo_visa === 1 ? "margin-left" : ""
                  }`}
                >
                  {broker.nombre} {broker.apellido}
                </span>
              </div>
            </button>
          ))
        ) : (
          <p className="emptySearch">No hay brokers en este país.</p>
        )}
      </div>
      {selectedBroker && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2>
                {selectedBroker.nombre} {selectedBroker.apellido}
              </h2>
            </div>
            <div className="modal-body">
              <div className="broker-image">
                <img
                  src={
                    selectedBroker.image_url
                      ? selectedBroker.image_url
                      : "/Logo-03.png"
                  }
                  alt={`${selectedBroker.nombre} ${selectedBroker.apellido}`}
                />
              </div>
              {selectedBroker.activo_visa === 1 && (
                <div className="broker-details">
                  <p>
                    <strong>Correo:</strong> {selectedBroker.email}
                  </p>
                  <p>
                    <strong>Teléfono:</strong> {selectedBroker.telefono}
                  </p>
                  <p>
                    <strong>Región:</strong> {selectedBroker.region}
                  </p>
                  <p>
                    <strong>País:</strong> {selectedBroker.pais}
                  </p>
                  <p>
                    <strong>Zoom:</strong>{" "}
                    <a
                      href={selectedBroker.link_zoom}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ir a la reunión
                    </a>
                  </p>
                  <p>{selectedBroker.mensaje}</p>

                  <div className="broker-socials">
                    {selectedBroker.link_facebook && (
                      <a
                        href={selectedBroker.link_facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook />
                      </a>
                    )}
                    {selectedBroker.link_instagram && (
                      <a
                        href={selectedBroker.link_instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram />
                      </a>
                    )}
                    {selectedBroker.link_tiktok && (
                      <a
                        href={selectedBroker.link_tiktok}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTiktok />
                      </a>
                    )}
                    {selectedBroker.link_youtube && (
                      <a
                        href={selectedBroker.link_youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube />
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button onClick={closeModal}>Cerrar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContinentScreen;
